import { useHookstate } from "@hookstate/core";
import {
  Card,
  Grid,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  Box,
} from "@mui/material";
import React, { useEffect } from "react";
import { globalState } from "../states/GlobalState";
import { PatientService } from "../../patient/services/PatientService";
import { IGetByIdPacienteResponse } from "../../patient/models/IGetByIdPacienteResponse";
import { getInitials } from "../utils/NameUtil";
import { useSnackbarCustom } from "../hooks/SnackbarHook";
import {useNavigate, useParams} from "react-router-dom";
import {AVALIACAO_DASHBOARD, CONSULTA_DASHBOARD} from "./AppRoutes";

interface PatientSelectedCardProps {
  patientId?: string;
  consultaId?: string;
  showPaginaInicialButton?: boolean;
  children?: React.ReactNode;
}

const PatientSelectedCard: React.FC<PatientSelectedCardProps> = ({
  patientId,
  consultaId,
  showPaginaInicialButton = true,
  children,
}) => {
  const { notifySuccess, notifyError } = useSnackbarCustom();
  const navigate = useNavigate();

  const state = useHookstate(globalState);
  const [iniciaisPaciente, setIniciaisPaciente] = React.useState<string>("");

  const calculateAge = (birthDate: Date): number => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    if (patientId) {
      PatientService.getById(patientId)
        .then((info: IGetByIdPacienteResponse) => {
          setIniciaisPaciente(getInitials(info.nome));

          state.set({
            ...state.value!,
            id: info.id,
            nome: info.nome,
            sexo: info.sexo === "MASCULINO" ? "M" : "F",
            idade: calculateAge(new Date(info.dataNascimento)),
          });
        })
        .catch((error) => notifyError(error));
    }
  }, [patientId]);

  return (
    <Box sx={{ mt: 2 }}>
      <Card style={{ boxShadow: "none" }}>
        <CardContent>
          <Grid container>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                sx={{ width: 80, height: 80 }}
                style={{ backgroundColor: "#ffae5d" }}
              >
                <Typography variant="h4" color="white">
                  {iniciaisPaciente}
                </Typography>
              </Avatar>
            </Grid>
            <Grid item xs={10}>
              <Box>
                <Typography variant="h6" color="secondary" display="inline">
                  {state.value?.nome}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                  sx={{ ml: 2 }}
                >
                  {state.value && state.value.idade} anos
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display="inline"
                  sx={{ ml: 2 }}
                >
                  {state.value && state.value.sexo === "M"
                    ? "Masculino"
                    : "Feminino"}
                </Typography>
              </Box>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <Box>
                {showPaginaInicialButton && (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => navigate(AVALIACAO_DASHBOARD(patientId!, consultaId!))}
                    sx={{ ml: 1, mr: 3 }}
                  >
                    Página inicial
                  </Button>
                )}
                {children}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PatientSelectedCard;
