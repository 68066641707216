import {Add, Edit, List} from "@mui/icons-material";
import {Button, Card, Checkbox, Paper, TableCell, TableRow} from "@mui/material";
import {Theme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PatientService} from "../services/PatientService";
import {IGetAllPacienteResponse} from "../models/IGetAllPacienteResponse";
import moment from "moment";
import ErrorDialog from "../../shared/dialogs/ErrorDialog";
import {
  CADASTRAR_PACIENTE,
  CONSULTA_DASHBOARD,
  EDITAR_PACIENTE,
} from "../../shared/components/AppRoutes";
import ContainerCustom from "../../shared/components/ContainerCustom";
import ListAppBar from "../../medidaCaseira/components/ListAppBar";
import TableCustom from "../../shared/components/TableCustom";
import {usePage} from "../../shared/hooks/PageHook";
import FilterPatientDialog, {
  IPatientFilter,
} from "../dialogs/FilterPatientDialog";
import {ODataResult} from "../../shared/models/ODataResult";
import {useSnackbarCustom} from "../../shared/hooks/SnackbarHook";
import PageTitle from "../../shared/components/PageTitle";

const PatientListPage: React.FC = () => {
  const navigate                     = useNavigate();
  const {notifySuccess, notifyError} = useSnackbarCustom();
  
  const [dialogOpen, setDialogOpen]                                   = useState(false);
  const [patients, setPatients]                                       = useState<IGetAllPacienteResponse[]>([]);
  const [isSelectingMode, setIsSelectingMode]                         = React.useState<boolean>(false);
  const [selecteds, setSelecteds]                                     = React.useState<string[]>([]);
  const {page, count, rowsPerPage, setRowsPerPage, setCount, setPage} =
          usePage();
  const [isFilterDialogOpened, setIsFilterDialogOpened]               =
          React.useState<boolean>(false);
  
  const handleEdit = () => {
    navigate(EDITAR_PACIENTE(selecteds[0]!));
  };
  
  const handleDelete = () => {
    if (selecteds.length === 0) return;
    
    PatientService.deleteAll(selecteds).then(() => {
      notifySuccess("Pacientes excluídos com sucesso!");
      setPage(0);
    }).catch((error) => {
      notifyError(error);
    });
  };
  
  const handleSelect = (id: string) => {
    let newSelecteds: string[] = [];
    
    if (selecteds.includes(id)) {
      newSelecteds = selecteds.filter((item) => item !== id);
      setSelecteds(newSelecteds);
    } else {
      newSelecteds = [...selecteds, id];
      setSelecteds(newSelecteds);
    }
    
    if (newSelecteds.length === 0) {
      isSelectingMode && setIsSelectingMode(false);
    } else {
      !isSelectingMode && setIsSelectingMode(true);
    }
  };
  
  const handleSelectOrDesselectAll = (checked: boolean) => {
    if (checked) {
      setSelecteds(patients.map((patient) => patient.id!));
      !isSelectingMode && setIsSelectingMode(true);
    } else {
      setSelecteds([]);
      isSelectingMode && setIsSelectingMode(false);
    }
  };
  
  const loadPage = (filter?: IPatientFilter) => {
    PatientService.getAll({
      page,
      numPerPage: rowsPerPage,
      nome: filter?.nome,
      sexo: filter?.sexo,
      dataNascimento: filter?.dataNascimento,
    }).then((data: ODataResult<IGetAllPacienteResponse[]>) => {
      if (data.count !== count) {
        setCount(data.count);
      }
      
      setPatients(data.data);
    }).catch((error) => {
      notifyError(error);
    });
  };
  
  useEffect(() => {
    loadPage();
  }, []);
  
  useEffect(() => {
    loadPage();
  }, [page, rowsPerPage]);
  
  return (
    <ContainerCustom>
      <PageTitle
        title="Pacientes"
        subtitle="Gerencie os pacientes e suas consultas"
        button={
          <Button
            variant="contained"
            startIcon={<Add/>}
            onClick={() => {
              navigate(CADASTRAR_PACIENTE);
            }}
            sx={{width: "150px", color: "white"}}
          >
            Adicionar
          </Button>
        }
      />
      <Card>
        <ListAppBar
          title="Pacientes Cadastrados"
          isEditEnabled={isSelectingMode}
          numSelecteds={selecteds.length}
          removeTitle="Deseja realmente excluir os pacientes selecionados?"
          removeMessage="Essa operação não poderá ser desfeita."
          onDelete={handleDelete}
          onFilter={() => setIsFilterDialogOpened(true)}
          menuActions={selecteds.length === 1 ? [
            {
              title: "Editar",
              onClick: () => selecteds.length === 1 && handleEdit(),
              icon: <Edit color="action"/>,
            },
            {
              title: "Consultas",
              onClick: () =>
                selecteds.length === 1 &&
                navigate(CONSULTA_DASHBOARD(selecteds[0]!)),
              icon: <List color="action"/>,
            },
          ] : []}
        />
        <TableCustom
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(page) => {
            setRowsPerPage(page);
          }}
          rowCount={patients.length}
          selectedCouunt={selecteds.length}
          emptyText={"Nenhum paciente encontrado."}
          headers={[
            {id: "nome", label: "Nome"},
            {id: "sexo", label: "Sexo"},
            {id: "dataNascimento", label: "Data de nascimento"},
          ]}
          onDesselectAll={() => handleSelectOrDesselectAll(false)}
          onSelectAll={() => handleSelectOrDesselectAll(true)}
        >
          {patients.map((patient) => {
            const isItemSelected = selecteds.includes(patient.id!);
            
            return (
              <TableRow
                key={patient.id}
                hover
                onClick={(event) => handleSelect(patient.id!)}
                selected={isItemSelected}
                role="checkbox"
                sx={{cursor: "pointer"}}
              >
                <TableCell>
                  <Checkbox color="primary" checked={isItemSelected}/>
                </TableCell>
                <TableCell>{patient.nome}</TableCell>
                <TableCell>
                  {patient.sexo === "MASCULINO" ? "Masculino" : "Feminino"}
                </TableCell>
                <TableCell>
                  {moment(patient.dataNascimento).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableCustom>
      </Card>
      
      <ErrorDialog
        open={dialogOpen}
        message="Erro ao buscar os pacientes"
        onClose={() => setDialogOpen(false)}
      />
      <FilterPatientDialog
        open={isFilterDialogOpened}
        onClose={() => setIsFilterDialogOpened(false)}
        onFilter={(filter) => {
          loadPage(filter);
        }}
      />
    </ContainerCustom>
  );
};

export default PatientListPage;
