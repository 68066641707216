import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";

interface CardCustomProps {
  title?: string;
  action?: JSX.Element;
  children: JSX.Element[] | JSX.Element;
}

const CardCustom: React.FC<CardCustomProps> = ({ title, action, children }) => {
  return (
    <Card sx={{ mt: 2 }} elevation={0}>
      <CardHeader title={title} action={action} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardCustom;
