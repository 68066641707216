import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Box,
  Button,
  TextField,
  Grid,
  CardHeader,
  useTheme,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../../shared/components/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import { ICreatePacienteRequest } from "../models/ICreatePacienteRequest";
import InputSelect from "../../shared/components/InputSelect";
import DateInput from "../../shared/components/DateInput";
import axios from "axios";
import TextInputMasked from "../../shared/components/TextInputMasked";
import NumberInput from "../../shared/components/NumberInput";
import { PatientService } from "../services/PatientService";
import { SexoEnum } from "../../shared/enums/SexoEnum";
import { UfEnum } from "../../shared/enums/UfEnum";
import { EtniaEnum } from "../../shared/enums/EtniaEnum";
import { EscolaridadeEnum } from "../../shared/enums/EscolaridadeEnum";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { LISTAR_PACIENTE } from "../../shared/components/AppRoutes";
import { isCPF, formatToCNPJ } from "brazilian-values";
import moment from "moment";
import {RoleGuard} from "../../shared/components/RoleGuard";
import {Add} from "@mui/icons-material";
import PageTitle from "../../shared/components/PageTitle";

const schemas = [
  yup.object().shape({
    nome: yup.string().required("Nome é obrigatório"),
    dataNascimento: yup
      .string()
      .test(
        "dataNascimento-test",
        "Data de nascimento inválida",
        function (value) {
          const date = moment(value);

          if (!date.isValid()) return false;

          return date.isBefore(moment());
        }
      )
      .required("Data de nascimento é obrigatória"),
    cpf: yup
      .string()
      .test("cpf-test", "CPF é inválido", function (value) {
        return isCPF(value ?? "");
      })
      .required("Cpf é obrigatória"),
    sexo: yup
      .string()
      .required("Sexo é obrigatório")
      .notOneOf(["NULL"], "Sexo é obrigatório"),
    etnia: yup
      .string()
      .required("Etnia é obrigatória")
      .notOneOf(["NULL"], "Etnia é obrigatório"),
    escolaridade: yup
      .string()
      .required("Escolaridade é obrigatória")
      .notOneOf(["NULL"], "Escolaridade é obrigatório"),
    ocupacao: yup.string(),
  }),
  yup.object().shape({
    cep: yup.string(),
    rua: yup.string().required("Rua é obrigatória"),
    numero: yup.string().required("Número é obrigatório"),
    complemento: yup.string(),
    bairro: yup.string().required("Bairro é obrigatório"),
    cidade: yup.string().required("Cidade é obrigatória"),
    estado: yup
      .string()
      .required("Estado é obrigatório")
      .notOneOf(["NULL"], "Estado é obrigatório"),
  }),
  yup.object().shape({
    email: yup
      .string()
      .email("E-mail deve ser válido")
      .required("E-mail é obrigatório"),
    telefonePrincipal: yup
      .string()
      .min(15, "Número inválido")
      .max(15, "Número inválido")
      .required("Telefone principal é obrigatório"),
    telefoneSecundario: yup
      .string()
      .min(15, "Número inválido")
      .max(15, "Número inválido")
      .nullable(),
  }),
];

const PatientFormPage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const steps = ["Dados pessoais", "Endereço", "Contatos"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    schemas[activeStep]
  );
  const [cep, setCep] = useState<string>("");

  const { id } = useParams();

  const sexoEnum = new SexoEnum().getForSelect();
  const escolaridadeEnum = new EscolaridadeEnum().getForSelect();
  const etniaEnum = new EtniaEnum().getForSelect();
  const ufEnum = new UfEnum().getForSelect();

  const handleContinue = () => {
    if (activeStep === steps.length - 1) {
      formik.submitForm();
      return;
    }

    if (activeStep < steps.length - 1) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      setCurrentValidationSchema(schemas[nextStep]);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      const nextStep = activeStep - 1;
      setActiveStep(nextStep);
      setCurrentValidationSchema(schemas[nextStep]);
    }
  };

  const handleCep = (e: string) => {
    formik.setValues({ ...formik.values, cep: e });
    setCep(e);
  };

  const onSubmit = (values: ICreatePacienteRequest) => {
    console.log(values);
    if (id)
      PatientService.edit(id, values)
        .then(() => {
          notifySuccess("Paciente editado com sucesso!");
          navigate(LISTAR_PACIENTE);
        })
        .catch((error) => notifyError(error));
    else
      PatientService.create(values)
        .then(() => {
          notifySuccess("Paciente criado com sucesso!");
          navigate(LISTAR_PACIENTE);
        })
        .catch((error) => notifyError(error));
  };

  const formik = useFormik<ICreatePacienteRequest>({
    initialValues: {
      nome: "",
      cpf: "",
      dataNascimento: "",
      sexo: "",
      etnia: "",
      escolaridade: "",
      profissao: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      estado: "",
      email: "",
      telPrincipal: "",
      telSecundario: "",
    },
    validationSchema: currentValidationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (id) {
      PatientService.getById(id)
        .then((response) => {
          formik.setValues({
            nome: response.nome,
            cpf: response.cpf,
            dataNascimento: response.dataNascimento,
            sexo: response.sexo,
            etnia: response.etnia,
            escolaridade: response.escolaridade,
            profissao: response.profissao,
            cep: response.cep,
            rua: response.rua,
            complemento: response.complemento,
            numero: response.numero,
            bairro: response.bairro,
            cidade: response.cidade,
            estado: response.estado,
            email: response.email,
            telPrincipal: response.telPrincipal,
            telSecundario: response.telSecundario,
          });
        })
        .catch((error) => notifyError(error));
    } else {
      formik.validateForm();
    }
  }, []);

  useEffect(() => {
    const getCpfData = setTimeout(() => {
      if (cep && cep.replace("-", "").length === 8) {
        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`)
          .then((response) => {
            const data = response.data;
            formik.setFieldValue("rua", data.logradouro);
            formik.setFieldValue("bairro", data.bairro);
            formik.setFieldValue("cidade", data.localidade);
            formik.setFieldValue("estado", data.uf);
          })
          .catch((error) => {
            notifyError("CEP inválido");
          });
      }
    }, 2000);

    return () => {
      clearTimeout(getCpfData);
    };
  }, [cep]);

  console.log(formik.errors);

  return (
    <Container
      maxWidth="xl"
      component="form"
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <PageTitle
        title={id ? "Editar Paciente" : "Adicionar Paciente"}
        subtitle={id ? "Editar as informações do paciente" : "Preencha as informações do paciente"}
      />
      <Card>
        <CardContent>
          <Stepper activeStep={activeStep} sx={{ mt: 2, mb: 3 }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <Typography style={{ color: theme.palette.secondary.main }}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box>
            {activeStep === 0 && (
              <Grid container>
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextInput
                      name="nome"
                      label="Nome"
                      formik={formik}
                      required
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={3} sx={{ mt: 2 }}>
                    <DateInput
                      label="Data de nascimento"
                      name="dataNascimento"
                      required
                      formik={formik}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ mt: 2 }}>
                    <TextInputMasked
                      name="cpf"
                      label="CPF"
                      mask="###.###.###-##"
                      required
                      formik={formik}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                    <InputSelect
                      label="Sexo"
                      required
                      name="sexo"
                      formik={formik}
                      options={sexoEnum}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                    <InputSelect
                      label="Etnia"
                      required
                      name="etnia"
                      formik={formik}
                      options={etniaEnum}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                    <InputSelect
                      label="Escolaridade"
                      required
                      name="escolaridade"
                      formik={formik}
                      options={escolaridadeEnum}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                    <TextField variant="outlined" label="Ocupação" fullWidth />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid container spacing={1}>
                <Grid item xs={12} md={2} sx={{ mt: 2 }}>
                  <TextInputMasked
                    mask="#####-###"
                    label="CEP"
                    name="cep"
                    value={cep}
                    onChange={(e) => handleCep(e)}
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ mt: 2 }}>
                  <TextInput label="Rua" name="rua" required formik={formik} />
                </Grid>
                <Grid item xs={12} md={2} sx={{ mt: 2 }}>
                  <NumberInput
                    label="Número"
                    name="numero"
                    required
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <TextInput
                    name="complemento"
                    label="Complemento"
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                  <TextInput
                    name="bairro"
                    label="Bairro"
                    required
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                  <TextInput
                    name="cidade"
                    label="Cidade"
                    required
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{ mt: 2 }}>
                  <InputSelect
                    name="estado"
                    label="Estado"
                    required
                    formik={formik}
                    options={ufEnum}
                  />
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid container rowSpacing={2} columnSpacing={1} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <TextInput
                    name="email"
                    label="E-mail"
                    required
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputMasked
                    name="telefonePrincipal"
                    label="Telefone principal"
                    mask="(##) #####-####"
                    required
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInputMasked
                    name="telefoneSecundario"
                    label="Telefone secundário"
                    mask="(##) #####-####"
                    formik={formik}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                variant="contained"
                sx={{ mt: 1, mr: 1 }}
                color="primary"
                onClick={() => handleContinue()}
                style={{ color: "white", boxShadow: "none" }}
                disabled={!formik.isValid}
              >
                {activeStep === steps.length - 1 ? "Finalizar" : "Continuar"}
              </Button>
              <Button
                disabled={activeStep === 0}
                variant="text"
                sx={{ mt: 1, mr: 1 }}
                onClick={() => handleBack()}
                color="primary"
                style={{ boxShadow: "none" }}
              >
                Voltar
              </Button>
            </div>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PatientFormPage;
