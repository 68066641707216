import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getAnamneseById, updateAnamnese } from "../services/EvalutionService";
import TextInput from "../../shared/components/TextInput";
import InputSelect from "../../shared/components/InputSelect";
import { PerguntaResponse } from "../models/IAnamneseResponse";
import SelecionarRecomendacaoDialog from "../dialogs/SelecionarRecomendacaoDialog";
import SelecionarPerguntaDialog from "../dialogs/SelecionarPerguntaDialog";
import useNovaAvaliacao from "../hooks/NovaAvaliacaoHook";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import NovaAvaliacaoButton from "../components/NovaAvaliacaoButton";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";

export interface IAnamneseResposta {
  id: string;
  valor: string;
}

const EvaluationAnamnesePage: React.FC = () => {
  const { id, consulta_id, avaliacaoId } = useParams();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const [perguntas, setPerguntas] = React.useState<PerguntaResponse[]>([]);
  const [respostas, setRespostas] = React.useState<IAnamneseResposta[]>([]);

  const {
    onNovaAvaliacao,
    isSelecionarAnamneseDialogOpen,
    setIsSelecionarAnamneseDialogOpen,
    isSelecionarRecomendacaoDialogOpen,
    setIsSelecionarRecomendacaoDialogOpen,
    onSelectAnamnese,
    onSelectRecomendacao,
  } = useNovaAvaliacao({ id: id!, consulta_id: consulta_id! });

  const handleChangeResposta = (
    index: number,
    value: any,
    isSelect: boolean = false
  ) => {
    const newRespostas = [...respostas];
    newRespostas[index].valor = isSelect ? value.target.value : value;
    setRespostas(newRespostas);
  };

  const handleChangeRadio = (index: number, value: any) => {
    const newRespostas = [...respostas];
    newRespostas[index].valor = value;
    setRespostas(newRespostas);
  };

  const isOptionChecked = (index: number, opt: string) => {
    const resposta = respostas[index];
    return resposta.valor.includes(opt);
  };

  const handleOptionChange = (
    index: number,
    opt: string,
    isChecked: boolean
  ) => {
    const resposta = respostas[index];
    const newResposta = resposta.valor.includes(opt)
      ? resposta.valor.split(",").filter((item) => item !== opt)
      : [...resposta.valor.split(","), opt];

    handleChangeResposta(index, newResposta.join(","));
  };

  const saveAnamnese = () => {
    updateAnamnese({ id: avaliacaoId!, respostas })
      .then(() => {
        notifySuccess("Anamnese salva com sucesso!");
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    if (!avaliacaoId) return;

    getAnamneseById(avaliacaoId)
      .then((data) => {
        setPerguntas(data.respostas.map((resposta) => resposta.pergunta));
        setRespostas(
          data.respostas.map((resposta) => ({
            id: resposta.id,
            valor: resposta.valor || "",
          }))
        );
      })
      .catch((error) => {
        notifyError(error);
      });
  }, [avaliacaoId]);

  return (
    <Container maxWidth="lg">
      <PatientSelectedCard patientId={id} consultaId={consulta_id} showPaginaInicialButton={true}>
        <NovaAvaliacaoButton onClick={(av) => onNovaAvaliacao(av)} />
        <Button
          variant="contained"
          color="secondary"
          onClick={saveAnamnese}
          sx={{ ml: 1 }}
        >
          Salvar
        </Button>
        {/* <Button variant="text" sx={{ ml: 1 }}>
          Imprimir
        </Button> */}
      </PatientSelectedCard>
      {perguntas.length > 0 ? (
        <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
          <CardHeader title="Questionário" />
          <CardContent>
            <List>
              {perguntas.map((pergunta, index) => (
                <ListItem key={index}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="start"
                    alignItems="stretch"
                  >
                    <Typography variant="h6">
                      {pergunta.ordem}. {pergunta.titulo}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {pergunta.subTitulo}
                    </Typography>
                    <div style={{ marginLeft: 30 }}>
                      {pergunta.tipoResposta === "TEXTO" && (
                        <TextInput
                          value={respostas[index].valor}
                          onChange={(e) => handleChangeResposta(index, e)}
                        />
                      )}
                      {pergunta.tipoResposta === "SELECT" && (
                        <InputSelect
                          value={respostas[index].valor || "NULL"}
                          onChange={(e) => handleChangeResposta(index, e, true)}
                          options={[
                            {
                              value: "NULL",
                              label: "Selecione",
                            },
                            ...pergunta.selectOpcoes.map((opt) => ({
                              value: opt,
                              label: opt,
                            })),
                          ]}
                        />
                      )}
                      {pergunta.tipoResposta === "RADIO" && (
                        <FormControl>
                          <RadioGroup
                            value={respostas[index].valor}
                            onChange={(e, value) =>
                              handleChangeRadio(index, value)
                            }
                          >
                            {pergunta.selectOpcoes.map((opt, optIndex) => (
                              <FormControlLabel
                                key={optIndex}
                                label={opt}
                                value={opt}
                                control={<Radio />}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )}
                      {pergunta.tipoResposta === "CHECKBOX" && (
                        <FormGroup>
                          {pergunta.selectOpcoes.map((opt, optIndex) => (
                            <FormControlLabel
                              key={optIndex}
                              label={opt}
                              control={
                                <Checkbox
                                  checked={isOptionChecked(index, opt)}
                                  onChange={(e, checked) =>
                                    handleOptionChange(index, opt, checked)
                                  }
                                />
                              }
                            />
                          ))}
                        </FormGroup>
                      )}
                    </div>
                  </Box>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Box>Nenhuma anamnese cadastrada</Box>
          </CardContent>
        </Card>
      )}

      <SelecionarRecomendacaoDialog
        open={isSelecionarRecomendacaoDialogOpen}
        onClose={() => setIsSelecionarRecomendacaoDialogOpen(false)}
        onSelect={onSelectRecomendacao}
      />

      <SelecionarPerguntaDialog
        open={isSelecionarAnamneseDialogOpen}
        onClose={() => setIsSelecionarAnamneseDialogOpen(false)}
        onSelect={onSelectAnamnese}
      />
    </Container>
  );
};

export default EvaluationAnamnesePage;
