import {Button, Card, Checkbox, Paper, TableCell, TableRow} from "@mui/material";
import ContainerCustom from "../../shared/components/ContainerCustom";
import React, {useEffect} from "react";
import {Add, Edit} from "@mui/icons-material";
import {AgrupadorPerguntaRequest} from "../models/AgrupadorPerguntaRequest";
import TableCustom from "../../shared/components/TableCustom";
import ListAppBar from "../../medidaCaseira/components/ListAppBar";
import {
  deleteAllPerguntas,
} from "../services/PerguntaService";
import {useNavigate} from "react-router-dom";
import {
  CADASTRAR_CONFIGURACAO_ANAMNESE, CADASTRAR_PACIENTE,
  EDITAR_CONFIGURACAO_ANAMNESE,
} from "../../shared/components/AppRoutes";
import {useTableFilter} from "../../shared/hooks/TableFilterHook";
import {ODataResult} from "../../shared/models/ODataResult";
import {usePage} from "../../shared/hooks/PageHook";
import FilterPerguntaDialog, {
  IPerguntaFilter,
} from "../dialogs/FilterPerguntaDialog";
import {useSnackbarCustom} from "../../shared/hooks/SnackbarHook";
import {getEntry} from "../../shared/utils/CrudService";
import PageTitle from "../../shared/components/PageTitle";

const PerguntaListPage = () => {
  const navigate                     = useNavigate();
  const {notifySuccess, notifyError} = useSnackbarCustom();
  
  const [perguntas, setPerguntas]                                     = React.useState<AgrupadorPerguntaRequest[]>(
    []
  );
  const [isFilterDialogOpened, setIsFilterDialogOpened]               =
          React.useState<boolean>(false);
  const [isSelectingMode, setIsSelectingMode]                         = React.useState<boolean>(false);
  const {filters, onAddFilter, onRemoveFilter, generateFilterString}  =
          useTableFilter();
  const [selecteds, setSelecteds]                                     = React.useState<string[]>([]);
  const [filterNome, setFilterNome]                                   = React.useState<string>("");
  const {page, count, rowsPerPage, setRowsPerPage, setCount, setPage} =
          usePage();
  
  const handleSelect = (id: string) => {
    let newSelecteds: string[] = [];
    
    if (selecteds.includes(id)) {
      newSelecteds = selecteds.filter((item) => item !== id);
      setSelecteds(newSelecteds);
    } else {
      newSelecteds = [...selecteds, id];
      setSelecteds(newSelecteds);
    }
    
    if (newSelecteds.length === 0) {
      isSelectingMode && setIsSelectingMode(false);
    } else {
      !isSelectingMode && setIsSelectingMode(true);
    }
  };
  
  const handleSelectOrDesselectAll = (checked: boolean) => {
    if (checked) {
      setSelecteds(perguntas.map((pergunta) => pergunta.id!));
      !isSelectingMode && setIsSelectingMode(true);
    } else {
      setSelecteds([]);
      isSelectingMode && setIsSelectingMode(false);
    }
  };
  
  const handleEdit = () => {
    navigate(EDITAR_CONFIGURACAO_ANAMNESE(selecteds[0]!));
  };
  
  const handleDelete = () => {
    if (selecteds.length === 0) return;
    
    deleteAllPerguntas(selecteds).then(() => {
      notifySuccess("Anamneses excluídas com sucesso!");
      setPage(1);
    }).catch((error) => {
      notifyError(error);
    });
  };
  
  const loadPage = (filter?: IPerguntaFilter) => {
    const searchParams = new URLSearchParams();
    
    filter?.nome && searchParams.append("nome", filter.nome);
    
    searchParams.append("skip", (page * rowsPerPage).toString());
    searchParams.append("take", rowsPerPage.toString());
    
    getEntry<ODataResult<AgrupadorPerguntaRequest[]>>(
      "agrupadorPergunta",
      searchParams
    ).then((data: ODataResult<AgrupadorPerguntaRequest[]>) => {
      if (data.count !== count) {
        setCount(data.count);
      }
      
      setPerguntas(data.data);
    }).catch((error) => {
      notifyError(error);
    });
  };
  
  useEffect(() => {
    loadPage();
  }, []);
  
  useEffect(() => {
    loadPage();
  }, [page, rowsPerPage, filters]);
  
  return (
    <ContainerCustom>
      <PageTitle
        title="Anamneses"
        subtitle="Gerencie as perguntas das anamneses"
        button={
          <Button
            variant="contained"
            startIcon={<Add/>}
            onClick={() => {
              navigate(CADASTRAR_CONFIGURACAO_ANAMNESE);
            }}
            sx={{width: "150px", color: "white"}}
          >
            Adicionar
          </Button>
        }
      />
      <Card elevation={0}>
        <ListAppBar
          title="Anamneses cadastradas"
          isEditEnabled={isSelectingMode}
          numSelecteds={selecteds.length}
          removeTitle="Deseja realmente excluir as anamneses selecionadas?"
          removeMessage="Essa operação não poderá ser desfeita."
          onDelete={handleDelete}
          onFilter={() => setIsFilterDialogOpened(true)}
          menuActions={
            selecteds.length === 1
              ? [
                {
                  title: "Editar",
                  onClick: () => handleEdit(),
                  icon: <Edit color="action"/>,
                },
              ]
              : []
          }
        />
        <TableCustom
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(page) => setPage(page)}
          onRowsPerPageChange={(page) => {
            setRowsPerPage(page);
          }}
          rowCount={perguntas.length}
          selectedCouunt={selecteds.length}
          emptyText={"Nenhuma anamnese encontrada."}
          headers={[
            {id: "nome", label: "Nome"},
            {id: "descricao", label: "Descrição"},
          ]}
          onDesselectAll={() => handleSelectOrDesselectAll(false)}
          onSelectAll={() => handleSelectOrDesselectAll(true)}
        >
          {perguntas.map((pergunta) => {
            const isItemSelected = selecteds.includes(pergunta.id!);
            
            return (
              <TableRow
                key={pergunta.id}
                hover
                onClick={(event) => handleSelect(pergunta.id!)}
                selected={isItemSelected}
                role="checkbox"
                sx={{cursor: "pointer"}}
              >
                <TableCell>
                  <Checkbox color="primary" checked={isItemSelected}/>
                </TableCell>
                <TableCell>{pergunta.titulo}</TableCell>
                <TableCell>{pergunta.subTitulo}</TableCell>
              </TableRow>
            );
          })}
        </TableCustom>
      </Card>
      
      <FilterPerguntaDialog
        open={isFilterDialogOpened}
        onClose={() => setIsFilterDialogOpened(false)}
        onFilter={(filter) => {
          loadPage(filter);
        }}
      />
    </ContainerCustom>
  );
};

export default PerguntaListPage;
