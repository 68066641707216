import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {AppBar, Badge, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import AppBar from "../components/AppBar";
import {Outlet} from "react-router-dom";
import {Notifications, Search} from "@mui/icons-material";

import UserMenu from "../components/UserMenu";
import BreadcrumbCustom from "../components/BreadcrumbCustom";
import Drawer from "../components/Drawer";

export default function Dashboard() {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [isDrawerOpened, setIsDrawerOpened] = React.useState(false);
  
  console.log(isSmallScreen);
  
  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);
  };
  
  return (
    <Box sx={{display: "flex", flexDirection: "row", width: "100%", height: "100vh"}}>
      <Drawer
        open={isDrawerOpened}
        setOpen={(open) => setIsDrawerOpened(open)}
        toggleDrawer={toggleDrawer}
        isSmallScreen={isSmallScreen}
      />
      <Box sx={{
        flex: 1,
      }}>
        <AppBar position="absolute" elevation={0} variant="elevation" color="transparent" sx={{
          left: isSmallScreen ? '0px' : '300px',
          width: isSmallScreen ? "100%" : "calc(100% - 300px)",
        }}>
          <Toolbar>
            {/*<IconButton*/}
            {/*  edge="start"*/}
            {/*  aria-label="open drawer"*/}
            {/*  onClick={toggleDrawer}*/}
            {/*  sx={{*/}
            {/*    marginRight: "36px",*/}
            {/*    ...(isDrawerOpened && {display: "none"}),*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <MenuIcon sx={{color: "white"}}/>*/}
            {/*</IconButton>*/}
            {/*<InputBase*/}
            {/*  placeholder="Pesquise no aplicativo..."*/}
            {/*  sx={{color: "white"}}*/}
            {/*/>*/}
            <IconButton>
              <Search/>
            </IconButton>
            <div style={{flexGrow: 1}}></div>
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Badge badgeContent={4} color="primary">
                <IconButton>
                  <Notifications/>
                </IconButton>
              </Badge>
              <UserMenu/>
            </Box>
          </Toolbar>
        </AppBar>
        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100%",
            mt: "64px",
          }}
        >
          {/* <Toolbar /> */}
          <BreadcrumbCustom/>
          <Box
            sx={{
              mt: 2,
              position: "relative",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          >
            <Outlet/>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
