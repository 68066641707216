import {Box, Typography} from "@mui/material";
import React from "react";

interface PageTitleProps  {
  title: string;
  subtitle: string;
  button?: JSX.Element;
}

const PageTitle = ({
  title,
  subtitle,
  button,
}: PageTitleProps) => {
 
 return (
   <Box sx={{
     mt: { xs: 2, md: 4 },
     mb: { xs: 2, md: 4 },
     display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
   }}>
     <Box>
       <Typography variant={"h4"}>{title}</Typography>
       <Typography variant={"subtitle1"} color="text.secondary">
         {subtitle}
       </Typography>
     </Box>
     {button}
   </Box>
 );
}

export default PageTitle;