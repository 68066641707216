import {ArrowBack} from "@mui/icons-material";
import {Breadcrumbs, IconButton, Link, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import {useLocation, Link as RouterLink} from "react-router-dom";
import {routes} from "../utils/BreadcrumbContants";
import {grey} from "@mui/material/colors";

interface ILink {
  label: string;
  url: string;
}

const BreadcrumbCustom: React.FC = () => {
  const {pathname} = useLocation();
  const segments   = pathname.split("/").filter((segment) => segment !== "");
  
  const isUuidV4 = (segment: string) => {
    return segment.match(
      /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
    );
  };
  
  const generateLinks = () => {
    let url   = "";
    let index = 0;
    
    const links: ILink[] = [];
    
    while (index < segments.length) {
      if (isUuidV4(segments[index])) {
        // Pega o proximo segmento
        const segment = segments[index];
        index++;
        
        if (index < segments.length) {
          const nextSegment = segments[index];
          url += `/${segment}/${nextSegment}`;
          links.push({label: nextSegment, url});
        } else index--;
      } else {
        url += `/${segments[index]}`;
        links.push({label: segments[index], url});
      }
      
      index++;
    }
    
    return links;
  };
  
  const links = generateLinks();
  
  const handleBackButton = () => {
    window.history.back();
  };
  
  console.log(links);
  
  return links.length > 0 ? (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      sx={{p: 1, backgroundColor: grey[100]}}
    >
      <Breadcrumbs separator="/" sx={{flexGrow: 1, ml: 1}}>
        {links.map((path, index) => {
          const isLast = index === links.length - 1;
          return isLast ? (
            <Typography key={index} color="text.secondary">
              {routes.get(path.label)}
            </Typography>
          ) : (
            <Typography key={index} color="text.disabled">
              {routes.get(path.label)}
            </Typography>
          );
        })}
      </Breadcrumbs>
    </Box>
  ) : (
    <></>
  );
};

export default BreadcrumbCustom;
