import { Delete, FilterList, MoreVert } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteContentDialog from "../../shared/dialogs/DeleteContentDialog";

export interface MenuAction {
  title: string;
  icon?: JSX.Element;
  onClick: () => void;
}

interface ListAppBarProps {
  title: string;
  menuActions?: MenuAction[];
  numSelecteds: number;
  isEditEnabled?: boolean;
  removeTitle?: string;
  removeMessage?: string;
  onFilter?: () => void;
  onDelete?: () => void;
}

const ListAppBar: React.FC<ListAppBarProps> = ({
  removeTitle,
  removeMessage,
  numSelecteds,
  onFilter,
  onDelete,
  isEditEnabled,
  title,
  menuActions,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const isMenuOpened = Boolean(menuAnchorEl);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = React.useState(false);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return !isEditEnabled ? (
    <Toolbar>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        height="100%"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
          flexGrow={1}
        >
          <Typography variant="h6" color="text.secondary">{title}</Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
          gap={1}
        >
          <IconButton onClick={onFilter}>
            <FilterList color="action" />
          </IconButton>
          {menuActions && menuActions.length > 0 && (
            <IconButton onClick={openMenu}>
              <MoreVert color="action" />
            </IconButton>
          )}
        </Box>
      </Box>

      <Menu open={isMenuOpened} onClose={closeMenu} anchorEl={menuAnchorEl}>
        <MenuList>
          {menuActions?.map((action, index) => (
            <MenuItem key={`menu-item-${index}`} onClick={action.onClick}>
              {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
              <ListItemText primary={action.title} />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Toolbar>
  ) : (
    <Toolbar
      sx={{ backgroundColor: "secondary.dark", borderRadius: "5px 5px 0 0" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
        height="100%"
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="start"
          justifyContent="start"
          flexGrow={1}
        >
          <Typography variant="h6" sx={{ color: "white" }}>
            <Typography variant="h5" display="inline" sx={{ color: "white" }}>
              {numSelecteds}{" "}
            </Typography>
            {numSelecteds > 1 || numSelecteds == 0
              ? `itens selecionados`
              : `item selecionado`}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setIsRemoveModalOpened(true);
          }}
        >
          <Delete sx={{ color: "white" }} />
        </IconButton>
        {menuActions && (
          <IconButton onClick={openMenu}>
            <MoreVert sx={{ color: "white" }} />
          </IconButton>
        )}
      </Box>
      <Menu open={isMenuOpened} onClose={closeMenu} anchorEl={menuAnchorEl}>
        <MenuList>
          {menuActions?.map((action, index) => (
            <MenuItem key={`menu-item-${index}`} onClick={action.onClick}>
              {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
              <ListItemText primary={action.title} />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <DeleteContentDialog
        open={isRemoveModalOpened}
        onClose={() => setIsRemoveModalOpened(false)}
        onAccept={() => {
          setIsRemoveModalOpened(false);
          onDelete && onDelete();
        }}
        title={removeTitle || ""}
        message={removeMessage || ""}
      />
    </Toolbar>
  );
};

export default ListAppBar;
