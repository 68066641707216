import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid, ListItem, List, Typography,
} from "@mui/material";
import AutocompleteInput from "../../shared/components/AutocompleteInput";
import {useFormik} from "formik";
import * as Yup from "yup";
import {isAllInputNotTouched} from "../../shared/utils/InputUtil";
import TextInput from "../../shared/components/TextInput";
import {createAgendamento, deleteAgendamento, updateAgendamento} from "../services/ScheduleService";
import DateTimeInput from "../../shared/components/DateTimeInput";
import {Check, Delete, NextPlan} from "@mui/icons-material";
import {useSnackbarCustom} from "../../shared/hooks/SnackbarHook";
import Grid2 from "@mui/material/Unstable_Grid2";

export interface ScheduleEventModalProps {
  open: boolean;
  nome?: string;
  id?: string;
  data?: string;
  email?: string;
  telefone?: string;
  obs?: string;
  status?: string;
  onClose: (reload: boolean) => void;
}

const ScheduleEventModal: React.FC<ScheduleEventModalProps> = ({
  open,
  onClose,
  nome,
  id,
  data,
  email,
  telefone,
  obs,
  status,
}) => {
  const {notifySuccess, notifyError} = useSnackbarCustom();
  
  const justClose = (reload?: boolean) => {
    onClose(reload || false);
  };
  
  return (
    <Dialog open={open} onClose={() => justClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle>{nome}</DialogTitle>
      <DialogContent>
        <List sx={{
          width: "100%",
        }}>
          <ListItem sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            p: 2,
            borderBottom: "1px solid #e0e0e0",
          }}>
            <Typography variant="body1">Data</Typography>
            <Typography variant="body1" fontWeight={600}>{data}</Typography>
          </ListItem>
          <ListItem sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            p: 2,
            borderBottom: "1px solid #e0e0e0",
          }}>
            <Typography variant="body1">Email</Typography>
            <Typography variant="body1" fontWeight={600}>{email}</Typography>
          </ListItem>
          <ListItem sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            p: 2,
            borderBottom: "1px solid #e0e0e0",
          }}>
            <Typography variant="body1">Telofone</Typography>
            <Typography variant="body1" fontWeight={600}>{telefone || "-"}</Typography>
          </ListItem>
          <ListItem sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            p: 2,
          }}>
            <Typography variant="body1">Status</Typography>
            <Typography variant="body1" fontWeight={600} color="primary">{status}</Typography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => justClose(false)} color="secondary">
          Fechar
        </Button>
        {status === "Agendada" && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<Check sx={{color: "white"}}/>}
            sx={{
              color: "white",
            }}
            onClick={() =>
              updateAgendamento(id!, {
                id: id!,
                dataHora: data!,
                status: "CONFIRMADA",
                obs: obs,
              }).then(() => justClose(true)).catch((error) => notifyError(error))
            }
          >
            Confirmar
          </Button>
        )}
        {status === "Confirmada" && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<NextPlan sx={{color: "white"}}/>}
            sx={{
              color: "white",
            }}
            onClick={() =>
              updateAgendamento(id!, {
                id: id!,
                dataHora: data!,
                status: "NAO_INICIADA",
                obs: obs,
              }).then(() => justClose(true)).catch((error) => notifyError(error))
            }
          >
            Próximo
          </Button>
        )}
        <Button
          variant="contained"
          color="error"
          startIcon={<Delete sx={{color: "error"}}/>}
          sx={{
            color: "white",
          }}
          onClick={() => {
            deleteAgendamento(id!)
            .then(() => {
              notifySuccess("Agendamento excluído com sucesso!");
              justClose(true);
            })
            .catch((error) => notifyError(error));
          }}
        >
          Exluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleEventModal;
