import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IGetConsultaPacienteDashboardResponse } from "../../consulta/models/IGetConsultaPacienteDashboardResponse";
import {
  createConsultaAvulsa,
  deleteConsulta,
  finalizarConsulta,
  getConsultaDashboard,
  iniciarConsulta,
} from "../../consulta/services/ConsultaService";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import PatientSelectedCard from "../../shared/components/PatientSelectedCard";
import { AVALIACAO_DASHBOARD } from "../../shared/components/AppRoutes";
import CardCustom from "../../shared/components/CardCustom";
import { LineChart, PieChart } from "@mui/x-charts";
import InputSelect from "../../shared/components/InputSelect";
import { Check, Delete, NextPlan } from "@mui/icons-material";
import ConfirmDialog from "../../shared/dialogs/ConfirmDialog";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import ContainerCustom from "../../shared/components/ContainerCustom";

const ConsultaDashboardPage: React.FC = () => {
  const theme = useTheme();
  const [dashboard, setDashboard] = React.useState<
    IGetConsultaPacienteDashboardResponse | undefined
  >(undefined);
  const [year, setYear] = React.useState<string>("");
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useSnackbarCustom();

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const onMenuAction = (
    consultaId: string,
    action: string,
    status?: string
  ) => {
    if (action === "excluir") {
      deleteConsulta(consultaId)
        .then(() => {
          if (id) {
            getConsultaDashboard(id)
              .then((response) => setDashboard(response))
              .catch((error) => notifyError(error));
          }
        })
        .catch((error) => {
          notifyError(error);
        });
    } else {
      if (status === "Não iniciada") {
        iniciarConsulta(consultaId)
          .then(() => {
            navigate(AVALIACAO_DASHBOARD(id!, consultaId));
          })
          .catch((error) => notifyError(error));
      } else if (status === "finalizar") {
        finalizarConsulta(consultaId)
          .then(() => {
            if (id) {
              getConsultaDashboard(id)
                .then((response) => setDashboard(response))
                .catch((error) => notifyError(error));
            }
          })
          .catch((error) => {
            notifyError(error);
          });
      } else {
        navigate(AVALIACAO_DASHBOARD(id!, consultaId));
      }
    }
  };

  const handleNewConsulta = () => {
    setDialogOpen(false);
    createConsultaAvulsa(id!)
      .then((response) => {
        notifySuccess("Consulta criada com sucesso!");
        getConsultaDashboard(id!)
          .then((response) => {
            setDashboard(response);
          })
          .catch((error) => {
            notifyError(error);
          });
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  useEffect(() => {
    if (id) {
      getConsultaDashboard(id)
        .then((response) => {
          setDashboard(response);
          setYear(response.ano.toString());
        })
        .catch((error) => notifyError(error));
    }
  }, []);

  return (
    <Container maxWidth="xl" sx={{mb: 2}}>
      <PatientSelectedCard patientId={id} showPaginaInicialButton={false}>
        <Button
          variant="contained"
          onClick={openDialog}
          style={{ color: "white" }}
        >
          Nova consulta
        </Button>
      </PatientSelectedCard>

      <Card sx={{ mt: 2 }} style={{ boxShadow: "none" }}>
        <CardHeader title="Últimas Consultas do Paciente" />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "text.secondary",
                      fontWeight: "bold",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: "text.secondary",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    Data da consulta
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "text.secondary",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    Quantidade de Avaliações
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "text.secondary",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "text.secondary",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboard && dashboard.consultas.length > 0 ? (
                  dashboard.consultas.map((consulta, index) => (
                    <TableRow key={consulta.id}>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">
                        {consulta.data
                          ? moment(consulta.data).format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {consulta.numAvaliacoes ? consulta.numAvaliacoes : "-"}
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          label={consulta.status}
                          color={consulta.status === "Realizada" ? "success" : "secondary"}
                          style={{ color: "white", fontWeight: "bold" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip title="Avaliações">
                          <IconButton
                            onClick={() =>
                              onMenuAction(
                                consulta.id,
                                "avaliacoes",
                                consulta.status
                              )
                            }
                          >
                            <NextPlan color="secondary" />
                          </IconButton>
                        </Tooltip>
                        {consulta.status !== "Não iniciada" &&
                          consulta.status !== "Realizada" && (
                            <Tooltip title="Finalizar">
                              <IconButton
                                onClick={() =>
                                  onMenuAction(
                                    consulta.id,
                                    "finalizar",
                                    consulta.status
                                  )
                                }
                              >
                                <Check color="info" />
                              </IconButton>
                            </Tooltip>
                          )}
                        <Tooltip title="Excluir">
                          <IconButton
                            onClick={() => onMenuAction(consulta.id, "excluir")}
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography
                        variant="body1"
                        textAlign="center"
                        color="secondary"
                      >
                        Nenhuma consulta encontrada.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {dashboard && dashboard.evolucaoPeso.length > 0 && (
        <CardCustom title="Evolução do peso">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "stretch",
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              alignItems="start"
            >
              <InputSelect
                width={200}
                fullWidth={false}
                label="Ano"
                name="ano"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                  getConsultaDashboard(id!, parseInt(e.target.value)).then(
                    (response) => setDashboard(response)
                  );
                }}
                options={dashboard.anosCadastrados.map((ano) => ({
                  label: ano.toString(),
                  value: ano.toString(),
                }))}
              />
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LineChart
                xAxis={[
                  {
                    data: dashboard?.evolucaoPeso.map((ev) => ev.mes),
                    tickMinStep: 1,
                    label: "Mês",
                    valueFormatter: (value) => {
                      switch (value) {
                        case 1:
                          return "Jan";
                        case 2:
                          return "Fev";
                        case 3:
                          return "Mar";
                        case 4:
                          return "Abr";
                        case 5:
                          return "Mai";
                        case 6:
                          return "Jun";
                        case 7:
                          return "Jul";
                        case 8:
                          return "Ago";
                        case 9:
                          return "Set";
                        case 10:
                          return "Out";
                        case 11:
                          return "Nov";
                        case 12:
                          return "Dez";
                        default:
                          return "";
                      }
                    },
                  },
                ]}
                series={[
                  {
                    data: dashboard?.evolucaoPeso.map((ev) => ev.peso),
                    label: "Peso",
                  },
                ]}
                width={400}
                height={250}
              />
            </div>
          </div>
        </CardCustom>
      )}

      {dashboard && dashboard.comparativoPeso && (
        <CardCustom title="Peso magro x Peso gordo x Peso ósseo">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      label: "Peso magro",
                      value: dashboard.comparativoPeso.pesoMagro,
                      color: "#9D7DF0",
                    },
                    {
                      id: 1,
                      label: "Peso gordo",
                      value: dashboard.comparativoPeso.pesoGordo,
                      color: "#E1333D",
                    },
                    {
                      id: 2,
                      label: "Peso ósseo",
                      value: dashboard.comparativoPeso.pesoOsseo,
                      color: "gray",
                    },
                  ],
                },
              ]}
              width={400}
              height={250}
            />
          </Box>
        </CardCustom>
      )}
      <ConfirmDialog
        open={dialogOpen}
        title="Nova consulta"
        message="Deseja criar uma nova consulta?"
        onClose={closeDialog}
        onConfirm={handleNewConsulta}
      />
    </Container>
  );
};

export default ConsultaDashboardPage;
