import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon, ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from "@mui/material";
import React, {useContext, useEffect} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useNavigate} from "react-router-dom";
import {LOGIN, PERFIL} from "./AppRoutes";
import {useAuth} from "../hooks/AuthHook";
import LogoutIcon from "@mui/icons-material/Logout";
import {Build, Person} from "@mui/icons-material";

const UserMenu: React.FC = () => {
  const theme                                  = useTheme();
  const {isAuthenticated, userLogged, singout} = useAuth();
  const navigate                               = useNavigate();
  const [anchorEl, setAnchorEl]                = React.useState<null | HTMLElement>(null);
  const [loggedUserName, setLoggedUserName]    = React.useState<string>();
  
  useEffect(() => {
    if (!isAuthenticated()) return;
    setLoggedUserName(userLogged()?.primeiroNome);
  }, []);
  
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = () => {
    setAnchorEl(null);
    singout();
    navigate(LOGIN);
  };
  
  const handlePerfil = () => {
    setAnchorEl(null);
    navigate(PERFIL);
  };
  
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Avatar
          alt={loggedUserName}
          color="primary.main"
          sx={{width: 40, height: 40,}}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            color="white"
          >
            {loggedUserName?.charAt(0) ?? "E"}
          </Typography>
        </Avatar>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: 5,
            boxShadow: theme.shadows[5],
            width: 250,
          },
        }}
      >
        <Box sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "start",
          p: 2,
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 0.5,
          }}>
            <Avatar
              alt={loggedUserName}
              color="primary.main"
              sx={{width: 50, height: 50,}}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                color="white"
              >
                {loggedUserName?.charAt(0) ?? "E"}
              </Typography>
            </Avatar>
          </Box>
          <Box sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            ml: 2,
          }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              {loggedUserName}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.disabled"
            >
              {'Administrador'}
            </Typography>
          </Box>
        </Box>
        <Divider/>
        <List>
          <ListItemButton
            onClick={() => {
              handlePerfil();
            }}
          >
            <ListItemIcon><Person/></ListItemIcon>
            <ListItemText primary="Perfil"/>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              // handlePerfil();
            }}
          >
            <ListItemIcon><Build/></ListItemIcon>
            <ListItemText primary="Configurações"/>
          </ListItemButton>
        </List>
        <Divider/>
        <Box p={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              handleLogout();
            }}
            endIcon={<LogoutIcon/>}
            sx={{
              color: "white",
            }}
          >
            Sair
          </Button>
        </Box>
      </Menu>
    </div>
  );
};

export default UserMenu;
