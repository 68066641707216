import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { IRegisterUserRequest } from "../models/RegisterUserRequest";
import AuthService from "../services/AuthService";
import { APP, LOGIN } from "../../shared/components/AppRoutes";
import ErrorDialog from "../../shared/dialogs/ErrorDialog";
import React from "react";
import YupPassword from "yup-password";
import { Divider } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../../shared/hooks/AuthHook";
import { Login } from "@mui/icons-material";

YupPassword(Yup); // extend yup

const schema = Yup.object().shape({
  primeiroNome: Yup.string().required("Campo obrigatório"),
  ultimoNome: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email().required("Campo obrigatório"),
  senha: Yup.string()
    .password()
    .required("Campo obrigatória")
    .min(6, "A senha deve ter no mínimo 6 caracteres")
    .minLowercase(1, "A senha deve ter no mínimo 1 letra minúscula")
    .minUppercase(1, "A senha deve ter no mínimo 1 letra maiúscula")
    .minSymbols(1, "A senha deve ter no mínimo 1 caractere especial")
    .minNumbers(1, "A senha deve ter no mínimo 1 número"),
  marketing: Yup.boolean(),
});

export default function SignUpPage() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState<string | null>(null);
  const { singin, isAuthenticated, isRemembered } = useAuth();

  const navigate = useNavigate();

  const openDialog = (message: string) => {
    setDialogMessage(message);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogMessage(null);
    setDialogOpen(false);
  };

  const onSubmit = (values: IRegisterUserRequest) => {
    AuthService.register(values)
      .then(() => {
        navigate(LOGIN);
      })
      .catch((error: Error) => {
        openDialog(error.message);
      });
  };

  const validate = (values: IRegisterUserRequest) => {};

  const intitialValues: IRegisterUserRequest = {
    email: "",
    senha: "",
    primeiroNome: "",
    ultimoNome: "",
    marketing: false,
  };

  const formik = useFormik<IRegisterUserRequest>({
    validationSchema: schema,
    initialValues: intitialValues,
    onSubmit: onSubmit,
    validate: validate,
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      AuthService.loginGoogle(codeResponse.access_token)
        .then((response) => {
          singin({
            email: response.email,
            primeiroNome: response.primeiroNome,
            ultimoNome: response.ultimoNome,
            token: response.token,
            expiresIn: new Date(response.expiresIn),
            isRemembered: false,
            roles: response.roles,
          });

          navigate(APP);
        })
        .catch((error: Error) => {
          openDialog(error.message);
        });
    },
    onError: (error) => {
      openDialog(error.error || "Erro ao fazer login");
    },
  });

  return (
    // <Box
    //   sx={{
    //     height: "100vh",
    //   }}
    // >
    //   <Container component="main" maxWidth="xs">
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           marginTop: 8,
    //           display: "flex",
    //           flexDirection: "column",
    //           alignItems: "center",
    //         }}
    //       >
    //         <img
    //           src={process.env.PUBLIC_URL + "/diety-logo.png"}
    //           alt="diety-logo"
    //           style={{ width: "10rem" }}
    //         />
    //       </Box>
    //       <Box
    //         component="form"
    //         noValidate
    //         onSubmit={formik.handleSubmit}
    //         sx={{ mt: 1 }}
    //       >
    // <Grid container spacing={2}>
    //   <Grid item xs={12} sm={6}>
    //     <TextField
    //       variant="outlined"
    //       autoComplete="given-name"
    //       name="primeiroNome"
    //       required
    //       fullWidth
    //       label="Primeiro nome"
    //       color="primary"
    //       value={formik.values.primeiroNome}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={
    //         formik.touched.primeiroNome &&
    //         Boolean(formik.errors.primeiroNome)
    //       }
    //       helperText={
    //         formik.touched.primeiroNome && formik.errors.primeiroNome
    //       }
    //     />
    //   </Grid>
    //   <Grid item xs={12} sm={6}>
    //     <TextField
    //       variant="outlined"
    //       required
    //       fullWidth
    //       label="Último nome"
    //       name="ultimoNome"
    //       color="primary"
    //       autoComplete="family-name"
    //       value={formik.values.ultimoNome}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={
    //         formik.touched.ultimoNome &&
    //         Boolean(formik.errors.ultimoNome)
    //       }
    //       helperText={
    //         formik.touched.ultimoNome && formik.errors.ultimoNome
    //       }
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <TextField
    //       variant="outlined"
    //       required
    //       fullWidth
    //       label="E-mail"
    //       color="primary"
    //       name="email"
    //       autoComplete="email"
    //       value={formik.values.email}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={formik.touched.email && Boolean(formik.errors.email)}
    //       helperText={formik.touched.email && formik.errors.email}
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <TextField
    //       variant="outlined"
    //       required
    //       fullWidth
    //       name="senha"
    //       label="Senha"
    //       type="password"
    //       color="primary"
    //       autoComplete="new-password"
    //       value={formik.values.senha}
    //       onBlur={formik.handleBlur}
    //       onChange={formik.handleChange}
    //       error={formik.touched.senha && Boolean(formik.errors.senha)}
    //       helperText={formik.touched.senha && formik.errors.senha}
    //     />
    //   </Grid>
    //   <Grid item xs={12}>
    //     <FormControlLabel
    //       control={
    //         <Checkbox value="allowExtraEmails" color="primary" />
    //       }
    //       label="Quero receber inspirações, promoções e atualizações via email."
    //       style={{ textAlign: "justify" }}
    //     />
    //   </Grid>
    // </Grid>
    // <Button
    //   type="submit"
    //   fullWidth
    //   disabled={!formik.isValid}
    //   variant="contained"
    //   sx={{ mt: 3, mb: 2, mr: 2 }}
    //   style={{ boxShadow: "none" }}
    // >
    //   <Typography color="white">Registre-se</Typography>
    // </Button>
    // <Grid container justifyContent="flex-end">
    //   <Grid item>
    //     <Link
    //       component={RouterLink}
    //       to={LOGIN}
    //       variant="body2"
    //       color="secondary"
    //     >
    //       Já possui uma conta? Faça o login
    //     </Link>
    //   </Grid>
    // </Grid>
    //       </Box>
    //     </Box>
    //   </Container>
    // <ErrorDialog
    //   open={dialogOpen}
    //   message={dialogMessage ?? ""}
    //   onClose={closeDialog}
    // />
    // </Box>
    <Box sx={{ height: "100vh", backgroundColor: "#fffbfb" }}>
      <Grid container component="main" sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              mr: 15,
              ml: 15,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                mt: { md: 2, xl: 5 },
                mb: { md: 1, xl: 3 },
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/diety-logo.png"}
                alt="login-logo"
                style={{ width: "10rem" }}
              />
            </Box>
            <Box
              sx={{
                mt: { md: 2, xl: 3 },
                mb: { md: 3, xl: 5 },
              }}
            >
              <Typography variant="h4" fontWeight="bold" textAlign="center">
                Crie sua conta.
              </Typography>
            </Box>
            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1 }}
            >
              {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="E-mail"
                name="email"
                color="primary"
                autoComplete="email"
                value={formik.values.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextInput
                required
                name="password"
                label="Senha"
                password
                margin="normal"
                formik={formik}
              />
              <Grid container mt={1} alignItems="center">
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={rememberMe}
                        onChange={(e, checked) => setRememberMe(checked)}
                        color="primary"
                      />
                    }
                    label="Salvar credenciais"
                  />
                </Grid>
                <Grid item>
                  <Link
                    to={FORGOT_PASSWORD}
                    component={RouterLink}
                    variant="body2"
                    // color="secondary"
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ color: "#0D0D0D", fontSize: "1rem" }}>
                      Esqueceu a senha?
                    </span>
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!formik.isValid}
                sx={{ mt: 2, mb: 2 }}
                style={{ boxShadow: "none" }}
                startIcon={<Login sx={{ color: "white" }} />}
              >
                <Typography color="white">Entrar</Typography>
              </Button>
              <Grid container mt={1} textAlign="center">
                <Grid item xs>
                  <Link
                    component={RouterLink}
                    to={REGISTER}
                    variant="body2"
                    // color="#0D0D0D"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    <span style={{ color: "#0D0D0D" }}>Não tem uma conta?</span>
                    <span> Registre-se</span>
                  </Link>
                </Grid>
              </Grid> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    autoComplete="given-name"
                    name="primeiroNome"
                    required
                    fullWidth
                    label="Primeiro nome"
                    color="primary"
                    value={formik.values.primeiroNome}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.primeiroNome &&
                      Boolean(formik.errors.primeiroNome)
                    }
                    helperText={
                      formik.touched.primeiroNome && formik.errors.primeiroNome
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Último nome"
                    name="ultimoNome"
                    color="primary"
                    autoComplete="family-name"
                    value={formik.values.ultimoNome}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.ultimoNome &&
                      Boolean(formik.errors.ultimoNome)
                    }
                    helperText={
                      formik.touched.ultimoNome && formik.errors.ultimoNome
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="E-mail"
                    color="primary"
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="senha"
                    label="Senha"
                    type="password"
                    color="primary"
                    autoComplete="new-password"
                    value={formik.values.senha}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.senha && Boolean(formik.errors.senha)}
                    helperText={formik.touched.senha && formik.errors.senha}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label="Quero receber inspirações, promoções e atualizações via email."
                    style={{ textAlign: "justify" }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                disabled={!formik.isValid}
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                style={{ boxShadow: "none" }}
                startIcon={<Login sx={{ color: "white" }} />}
              >
                <Typography color="white">Registre-se</Typography>
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link
                    component={RouterLink}
                    to={LOGIN}
                    variant="body2"
                    // color="secondary"
                    style={{ textDecoration: "none", fontSize: "1rem" }}
                  >
                    <span style={{ color: "#0D0D0D" }}>
                      Já possui uma conta?
                    </span>
                    <span> Faça o login</span>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          md={6}
          sx={{
            display: { xs: "none", lg: "flex" },
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/sign-up-image.png"
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></Grid>
      </Grid>
      <ErrorDialog
        open={dialogOpen}
        message={dialogMessage ?? ""}
        onClose={closeDialog}
      />
    </Box>
  );
}
