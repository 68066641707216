import {
  Container,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  StepContent,
  CardHeader,
  useTheme,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputSelect from "../../shared/components/InputSelect";
import { ICreateFoodRequest } from "../models/ICreateFoodRequest";
import { FoodService } from "../services/FoodService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import TextInput from "../../shared/components/TextInput";
import NumberInput from "../../shared/components/NumberInput";
import * as yup from "yup";
import { getTipoAlimentoForSelect } from "../../shared/enums/TipoAlimentoEnum";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import { LISTAR_ALIMENTOS } from "../../shared/components/AppRoutes";
import ContainerCustom from "../../shared/components/ContainerCustom";
import PageTitle from "../../shared/components/PageTitle";

const schemas = [
  yup.object().shape({
    nome: yup.string().required("Nome é obrigatório"),
    tipoAlimento: yup.string().required("Tipo de alimento é obrigatório"),
  }),
  yup.object().shape({
    kcal: yup
      .number()
      .required("Kcal é obrigatório")
      .moreThan(0, "Kcal deve ser maior que 0"),
    proteina: yup
      .number()
      .nullable()
      .moreThan(-1, "Kcal deve ser maior ou igual a 0"),
    lipidio: yup
      .number()
      .nullable()
      .moreThan(-1, "Kcal deve ser maior ou igual a 0"),
    carboidrato: yup
      .number()
      .nullable()
      .moreThan(-1, "Kcal deve ser maior ou igual a 0"),
  }),
  yup.object().shape({
    calcio: yup
      .number()
      .nullable()
      .moreThan(-1, "Cálcio deve ser maior ou igual a 0"),
    fosforo: yup
      .number()
      .nullable()
      .moreThan(-1, "Fósforo deve ser maior ou igual a 0"),
    ferro: yup
      .number()
      .nullable()
      .moreThan(-1, "Ferro deve ser maior ou igual a 0"),
    sodio: yup
      .number()
      .nullable()
      .moreThan(-1, "Sódio deve ser maior ou igual a 0"),
    potassio: yup
      .number()
      .nullable()
      .moreThan(-1, "Potássio deve ser maior ou igual a 0"),
    vitaminaC: yup
      .number()
      .nullable()
      .moreThan(-1, "Vitamina C deve ser maior ou igual a 0"),
  }),
];

const FoodFormPage: React.FC = () => {
  const steps = ["Geral", "Macronutrientes", "Micronutrientes"];
  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const { id } = useParams();
  const location = useLocation();
  const { notifySuccess, notifyError } = useSnackbarCustom();
  const isViewMode = location.pathname.includes("visualizar");

  const [currentValidationSchema, setCurrentValidationSchema] = useState(
    schemas[activeStep]
  );

  const handleContinue = () => {
    if (activeStep === steps.length - 1 && !isViewMode) {
      formik.submitForm();
      return;
    }

    if (activeStep === steps.length - 1 && isViewMode) {
      navigate(LISTAR_ALIMENTOS);
      return;
    }

    if (activeStep < steps.length - 1) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      setCurrentValidationSchema(schemas[nextStep]);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      const nextStep = activeStep - 1;
      setActiveStep(nextStep);
      setCurrentValidationSchema(schemas[nextStep]);
    }
  };

  const onSubmit = (values: ICreateFoodRequest) => {
    if (id === null || id === undefined) {
      FoodService.create(values).then(() => {
        notifySuccess("Alimento criado com sucesso!");
        navigate(LISTAR_ALIMENTOS);
      });
    } else {
      FoodService.edit(id ?? "", values).then(() => {
        notifySuccess("Alimento editado com sucesso!");
        navigate(LISTAR_ALIMENTOS);
      });
    }
  };

  const formik = useFormik<ICreateFoodRequest>({
    initialValues: {
      nome: "",
      tipoAlimento: "",
      kcal: undefined,
      proteina: undefined,
      lipidio: undefined,
      carboidrato: undefined,
      calcio: undefined,
      fosforo: undefined,
      ferro: undefined,
      sodio: undefined,
      potassio: undefined,
      vitaminaC: undefined,
    },
    validationSchema: currentValidationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (id) {
      FoodService.getById(id)
        .then((response) => {
          formik.setValues({
            nome: response.nome,
            tipoAlimento: response.tipoAlimento,
            kcal: response.kcal,
            proteina: response.proteina,
            lipidio: response.lipidio,
            carboidrato: response.carboidrato,
            calcio: response.calcio,
            fosforo: response.fosforo,
            ferro: response.ferro,
            sodio: response.sodio,
            potassio: response.potassio,
            vitaminaC: response.vitaminaC,
          });
        })
        .catch((error) => {
          notifyError(error);
        });
    } else {
      formik.validateForm();
    }
  }, []);

  return (
    <ContainerCustom>
      <PageTitle
        title={id ? "Editar Alimento" : "Adicionar Alimento"}
        subtitle={id ? "Edite as informações do alimento" : "Preencha as informações do alimento"}
      />
      <Card elevation={0}>
        <CardContent>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <Typography style={{ color: theme.palette.secondary.main }}>
                    {label}
                  </Typography>
                </StepLabel>
                <StepContent>
                  {activeStep === 0 && (
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 400,
                        gap: 2,
                      }}
                    >
                      <TextInput
                        name="nome"
                        label="Nome"
                        formik={formik}
                        required
                        disabled={isViewMode}
                      />
                      <InputSelect
                        label="Grupo"
                        required
                        name="tipoAlimento"
                        formik={formik}
                        options={getTipoAlimentoForSelect()}
                        disabled={isViewMode}
                      />
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 400,
                      }}
                    >
                      <NumberInput
                        label="Kcal"
                        name="kcal"
                        required
                        formik={formik}
                        endAdornment="g"
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Carboidratos"
                        name="carboidrato"
                        formik={formik}
                        endAdornment="g"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Lipídeos"
                        name="lipidio"
                        formik={formik}
                        endAdornment="g"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Proteínas"
                        name="proteina"
                        formik={formik}
                        endAdornment="g"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                    </Box>
                  )}
                  {activeStep === 2 && (
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: 400,
                      }}
                    >
                      <NumberInput
                        label="Sódio"
                        name="sodio"
                        formik={formik}
                        endAdornment="mg"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Potássio"
                        name="potassio"
                        formik={formik}
                        endAdornment="mg"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Cálcio"
                        name="calcio"
                        formik={formik}
                        endAdornment="mg"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Ferro"
                        name="ferro"
                        formik={formik}
                        endAdornment="mg"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Fósforo"
                        name="fosforo"
                        formik={formik}
                        endAdornment="mg"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                      <NumberInput
                        label="Vitamina C"
                        name="vitaminaC"
                        formik={formik}
                        endAdornment="mg"
                        marginTop={1}
                        disabled={isViewMode}
                      />
                    </Box>
                  )}
                  <Box sx={{ mb: 2, mt: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => handleContinue()}
                        color="primary"
                        style={{ boxShadow: "none", color: "white" }}
                        disabled={!formik.isValid}
                      >
                        {index === steps.length - 1 && !isViewMode
                          ? "Finalizar"
                          : index === steps.length - 1 && isViewMode
                          ? "Voltar Para Listagem"
                          : "Continuar"}
                      </Button>
                      <Button
                        variant="text"
                        disabled={index === 0}
                        sx={{ mt: 1, mr: 1 }}
                        onClick={() => handleBack()}
                        color="primary"
                        style={{ boxShadow: "none" }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
    </ContainerCustom>
  );
};

export default FoodFormPage;
