import React, { useEffect } from "react";
import ContainerCustom from "../../shared/components/ContainerCustom";
import { useNavigate, useParams } from "react-router-dom";
import { createPerguntas, getPerguntaById } from "../services/PerguntaService";
import {
  Box,
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardHeader,
  ButtonBase,
  ListItemIcon,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  AgrupadorPerguntaRequest,
  PerguntaRequest,
} from "../models/AgrupadorPerguntaRequest";
import TextInput from "../../shared/components/TextInput";
import { isAllInputNotTouched } from "../../shared/utils/InputUtil";
import CreatePerguntaDialog from "../dialogs/CreatePerguntaDialog";
import { Add, Delete, DragIndicator, Edit } from "@mui/icons-material";
import { CONFIGURACAO_ANAMNESE } from "../../shared/components/AppRoutes";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbarCustom } from "../../shared/hooks/SnackbarHook";
import PageTitle from "../../shared/components/PageTitle";

const schemas = [
  yup.object().shape({
    titulo: yup.string().required("Nome é obrigatório"),
    subTitulo: yup.string(),
  }),
  yup.object().shape({
    perguntas: yup.array().required("Pelo menos uma pergunta é obrigatória"),
  }),
];

const initialValues: AgrupadorPerguntaRequest = {
  titulo: "",
  subTitulo: "",
  perguntas: [],
};

const PerguntaFormPage = () => {
  const { id } = useParams<{ id: string }>();
  const { notifySuccess, notifyError } = useSnackbarCustom();
  const navigate = useNavigate();

  const steps = ["Geral", "Perguntas"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentSchema, setCurrentSchema] = React.useState(schemas[activeStep]);
  const [isPerguntaDialogShowing, setIsPerguntaDialogShowing] =
    React.useState(false);
  const [selected, setSelected] = React.useState(-1);

  const onSubmit = (values: AgrupadorPerguntaRequest) => {
    createPerguntas(values)
      .then(() => {
        notifySuccess("Anamnese cadastrada com sucesso");
        navigate(CONFIGURACAO_ANAMNESE);
      })
      .catch((error) => {
        notifyError(error);
      });
  };

  const formik = useFormik<AgrupadorPerguntaRequest>({
    initialValues,
    validationSchema: currentSchema,
    onSubmit,
  });

  const handleContinue = () => {
    if (activeStep === steps.length - 1) {
      formik.submitForm();
      return;
    }

    if (activeStep === steps.length - 1) {
      // navigate("/app/alimento");
      return;
    }

    if (activeStep < steps.length - 1) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
      setCurrentSchema(schemas[nextStep]);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      const nextStep = activeStep - 1;
      setActiveStep(nextStep);
      setCurrentSchema(schemas[nextStep]);
    }
  };

  const handleAddPergunta = (pergunta: PerguntaRequest) => {
    pergunta.ordem = formik.values.perguntas.length;
    formik.setFieldValue("perguntas", [...formik.values.perguntas, pergunta]);
  };

  const openAddPergunta = () => {
    if (selected != -1) {
      setSelected(-1);
    }

    setIsPerguntaDialogShowing(true);
  };

  const removePergunta = () => {
    if (selected === -1) {
      return;
    }

    formik.setFieldValue(
      "perguntas",
      formik.values.perguntas.filter((_, index) => index !== selected)
    );

    setSelected(-1);
  };

  const handleDropList = (result: any) => {
    // Check if the item was dropped outside of the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // Create a new copy of the recommendations array
    const newRecomendacoes = Array.from(formik.values.perguntas);

    // Remove the dragged item from its original position
    const [removed] = newRecomendacoes.splice(source.index, 1);

    // Insert the dragged item at its new position
    newRecomendacoes.splice(destination.index, 0, removed);

    // Update the order of the recommendations
    newRecomendacoes.forEach((item, index) => {
      item.ordem = index;
    });

    // Update the formik values
    formik.setFieldValue("perguntas", newRecomendacoes);

    setSelected(-1);
  };

  useEffect(() => {
    if (!id) return;
    getPerguntaById(id)
      .then((response) => {
        formik.setValues(response);
      })
      .catch((error) => {
        notifyError(error);
      });
  }, [id]);

  return (
    <ContainerCustom>
      <PageTitle
        title={id ? "Editar Anamnese" : "Adicionar Anamnese"}
        subtitle={id ? "Editar as informações da anamnese e suas perguntas" : "Preencha as informações da anamnese e suas perguntas"}
      />
      <Card elevation={0}>
        <Stepper activeStep={activeStep} orientation="vertical" sx={{ p: 2, }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                <Typography sx={{ color: "secondary.main" }}>
                  {label}
                </Typography>
              </StepLabel>
              <StepContent>
                {activeStep === 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name="titulo"
                        label="Nome"
                        formik={formik}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        name="subTitulo"
                        label="Descrição"
                        formik={formik}
                      />
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DragDropContext onDragEnd={handleDropList}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <List
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {formik.values.perguntas.map(
                                (recomendacao, index) => {
                                  return (
                                    <Draggable
                                      index={index}
                                      key={recomendacao.id}
                                      draggableId={recomendacao.id!}
                                    >
                                      {(provided) =>
                                        selected === index ? (
                                          <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <ListItemIcon>
                                              <DragIndicator
                                                sx={{ color: "text.secondary" }}
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={recomendacao.titulo}
                                              secondary={
                                                recomendacao.selectOpcoes
                                                  ?.length > 0
                                                  ? `[${recomendacao.selectOpcoes.join(
                                                      ","
                                                    )}]`
                                                  : ""
                                              }
                                            />
                                            <ListItemSecondaryAction>
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                              >
                                                <Button
                                                  variant="contained"
                                                  color="info"
                                                  sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Edit
                                                    sx={{ color: "white" }}
                                                  />
                                                  Editar
                                                </Button>

                                                <Button
                                                  variant="contained"
                                                  color="error"
                                                  onClick={removePergunta}
                                                  sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    ml: 1,
                                                  }}
                                                >
                                                  <Delete
                                                    sx={{ color: "white" }}
                                                  />
                                                  Deletar
                                                </Button>
                                              </Box>
                                            </ListItemSecondaryAction>
                                          </ListItem>
                                        ) : (
                                          <ListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => setSelected(index)}
                                          >
                                            <ListItemIcon>
                                              <DragIndicator
                                                sx={{ color: "text.secondary" }}
                                              />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={recomendacao.titulo}
                                              secondary={
                                                recomendacao.selectOpcoes
                                                  ?.length > 0
                                                  ? `[${recomendacao.selectOpcoes.join(
                                                      ","
                                                    )}]`
                                                  : ""
                                              }
                                            />
                                          </ListItem>
                                        )
                                      }
                                    </Draggable>
                                  );
                                }
                              )}
                              {provided.placeholder}
                              <ListItem key={`index-botao`}>
                                <ButtonBase
                                  onClick={openAddPergunta}
                                  sx={{ width: "100%", height: "70px" }}
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="row"
                                  >
                                    <Add sx={{ color: "text.disabled" }} />
                                    <Typography
                                      variant="h6"
                                      color="text.disabled"
                                    >
                                      Adicionar pergunta
                                    </Typography>
                                  </Box>
                                </ButtonBase>
                              </ListItem>
                            </List>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </Grid>
                  </Grid>
                )}
                <Box sx={{ mb: 2, mt: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      sx={{ mt: 1, mr: 1 }}
                      onClick={() => handleContinue()}
                      color="primary"
                      style={{ boxShadow: "none", color: "white" }}
                      disabled={isAllInputNotTouched(formik) || !formik.isValid}
                    >
                      {index === steps.length - 1
                        ? "Finalizar"
                        : index === steps.length - 1
                        ? "Voltar Para Listagem"
                        : "Continuar"}
                    </Button>
                    <Button
                      variant="text"
                      disabled={index === 0}
                      sx={{ mt: 1, mr: 1 }}
                      onClick={() => handleBack()}
                      color="primary"
                      style={{ boxShadow: "none" }}
                    >
                      Voltar
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Card>

      <CreatePerguntaDialog
        open={isPerguntaDialogShowing}
        onClose={() => setIsPerguntaDialogShowing(false)}
        onAdd={handleAddPergunta}
      />
    </ContainerCustom>
  );
};

export default PerguntaFormPage;
