import React, {useEffect, useRef} from "react";
import {
  Box,
  Button, ButtonGroup,
  Card,
  CardContent,
  Container, IconButton,
  ToggleButton, ToggleButtonGroup, Typography,
} from "@mui/material";
import {
  Add, ArrowLeft, ArrowRight,
} from "@mui/icons-material";
import ScheduleCreateModal from "../modals/ScheduleCreateModal";
import {
  getAllAgendamentoByMes,
} from "../services/ScheduleService";
import {useSnackbarCustom} from "../../shared/hooks/SnackbarHook";
import "./Calendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid";
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import ScheduleEventModal from "../modals/ScheduleEventModal";
import {IGetSchedulingByMonthResponse} from "../models/GetSchedulingByMonthResponse";
import moment from "moment";
import PageTitle from "../../shared/components/PageTitle";

const ScheduleListPage: React.FC<{ agendar: boolean }> = ({agendar}) => {
  const {notifyError} = useSnackbarCustom();
  
  const [open, setOpen]     = React.useState(agendar);
  const [reload, setReload] = React.useState(true);
  
  const [isEventModalOpen, setIsEventModalOpen] = React.useState(false);
  const [selectedEvent, setSelectedEvent]       = React.useState<IGetSchedulingByMonthResponse | undefined>();
  
  const calendarRef                     = useRef<any>();
  const [calendarView, setCalendarView] = React.useState(["dayGridWeek"]);
  const [currentDate, setCurrentDate]   = React.useState("");
  
  const [datasource, setDatasource] = React.useState<IGetSchedulingByMonthResponse[]>([]);
  
  const loadMoreAgendamentos = (mes: number, ano: number) => {
    getAllAgendamentoByMes(mes, ano).then((data) => {
      setDatasource(data);
    }).catch((error) => {
      notifyError(error);
    });
  };
  
  const handleCalendarViewChange = (view: string) => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view);
    
    setCalendarView([view]);
    setCurrentDate(calendarApi.view.title);
  }
  
  const handlePreviousButtonClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentDate(calendarApi.view.title);
  }
  
  const handleNextButtonClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentDate(calendarApi.view.title);
  }
  
  const handleEventClick = (info: any) => {
    console.log(info.event);
    const event = datasource.find((item) => item.id === info.event.id);
    
    if (event) {
      setSelectedEvent(event);
      setIsEventModalOpen(true);
    }
  }
  
  useEffect(() => {
    if (reload) {
      loadMoreAgendamentos(new Date().getMonth() + 1, new Date().getFullYear());
      setReload(false);
    }
  }, [reload]);
  
  useEffect(() => {
    if (calendarRef.current) {
      let calendarApi = calendarRef.current.getApi();
      setCurrentDate(calendarApi.view.title);
    }
  }, [calendarRef]);
  
  return (
    <Container maxWidth="xl">
      <PageTitle
        title="Agendamentos"
        subtitle="Gerencie os agendamentos dos seus pacientes"
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            startIcon={<Add className="plus-button"/>}
            sx={{
              color: "white",
              width: 200,
            }}
          >
            Agendar
          </Button>
        }
      />
      <Card style={{boxShadow: "none"}}>
        <CardContent>
          <Typography variant="h5" mb={2}>{currentDate}</Typography>
          <Box sx={{
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <ButtonGroup variant="outlined">
              <IconButton onClick={handlePreviousButtonClick}><ArrowLeft fontSize="large"/></IconButton>
              <IconButton onClick={handleNextButtonClick}><ArrowRight fontSize="large"/></IconButton>
            </ButtonGroup>
            <ToggleButtonGroup exclusive={true} color="primary" value={calendarView}
                               onChange={(e, value) => handleCalendarViewChange(value)}>
              <ToggleButton value="dayGridMonth">Mês</ToggleButton>
              <ToggleButton value="dayGridWeek">Semana</ToggleButton>
              <ToggleButton value="dayGridDay">Dia</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="timeGridWeek"
            weekends={true}
            selectable={true}
            editable={true}
            dayMaxEvents={20}
            events={datasource.map((item) => ({
              id: item.id,
              allDay: false,
              start: item.data,
              title: item.paciente,
              className: item.status === 'Agendada' ? 'bg-secondary' : 'bg-primary',
            }))}
            dateClick={() => setOpen(true)}
            headerToolbar={false}
            locale={ptBrLocale}
            eventClick={handleEventClick}
          />
        </CardContent>
      </Card>
      <ScheduleCreateModal
        open={open}
        onClose={(reload) => {
          setOpen(false);
          setReload(reload);
        }}
      />
      <ScheduleEventModal
        open={isEventModalOpen} nome={selectedEvent?.paciente} id={selectedEvent?.id}
        data={selectedEvent ? moment(selectedEvent.data).format('DD/MM/yyyy HH:mm') : undefined}
        email={selectedEvent?.email} status={selectedEvent?.status} telefone={selectedEvent?.telefone}
        onClose={(reload) => {
          if (reload) {
            setReload(true);
          }
          
          setSelectedEvent(undefined);
          setIsEventModalOpen(false);
        }}/>
    </Container>
  );
};

export default ScheduleListPage;
